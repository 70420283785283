
class Header {

	static name() { return 'Header'; }

	constructor(props, el) {
		this.props = props;
		this.el = el;
		this.$el = $(el);

		this.objects = {
			$navbar: this.$el.find('.navbar'),
			$navbarContent: this.$el.find('#navbar-content'),
		};
	}

	init() {
		console.info('~~~ Header ~~~');

		const body = $('body');
		const { $navbarContent } = this.objects;

		// lock scrolling on body when toggle navigation is expanded
		$navbarContent
			.on('hidden.bs.collapse', () => body.removeClass('overflow-hidden'))
			.on('shown.bs.collapse', () => body.addClass('overflow-hidden'));

		$('.dropdown-menu a[data-toggle="dropdown"]').on('click', function(e) {
			if (!$(this).next().hasClass('show')) {
				$(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
			}
			var $subMenu = $(this).next(".dropdown-menu");
			$subMenu.toggleClass('show');
			$(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
				$('.dropdown-submenu .show').removeClass("show");
			});
			return false;
		});

		$('.sub-nav[data-toggle="sub-nav-dropdown"]').on('mouseenter', function(e) {
			console.info('---- mouse enter ----');
			var $subMenu = $(this).find(".dropdown-menu");
			$subMenu.toggleClass('show');
			return false;
		}).on('mouseleave', function() {
			console.info('---- mouse leave ----');
			$(this).find('.dropdown-menu').removeClass("show");
			return false;
		});
	}

}

export default Header;
