
class CarouselContainer {
    static name() { return 'CarouselContainer'; }
    static refreshable() { return true; }

    constructor(props, el) {
		this.props = props;
		this.el = el;
    }

    init() {
        console.info('~~~ Carousel Container ~~~');

        const { 
            component, 
            slidesperview,
            autoslide,
            delay,
            margin,
         } = this.el.dataset;
        const swiperEl = document.getElementById(`swiper_container_${component}`);
        const prevControl = document.getElementById(`swiper_prev_${component}`);
        const nextControl = document.getElementById(`swiper_next_${component}`);

        const swiperParams = {
            pagination: {
                clickable: true,
            },
            spaceBetween: margin,
            loop: true,
            slidesPerView: 1,
            breakpoints: {
                576: {
                    slidesPerView: slidesperview,
                },
            },
        };

        if (autoslide) {
            swiperParams.autoplay = { delay };
        }

        Object.assign(swiperEl, swiperParams);
        swiperEl.initialize();

        prevControl.addEventListener('click', (e) => {
            swiperEl.swiper.slidePrev();
            e.preventDefault();
        });

        nextControl.addEventListener('click', (e) => {
            swiperEl.swiper.slideNext();
            e.preventDefault();
        });
    }
}

window['loadCarouselContainers'] = function () {
    document.querySelectorAll('div[data-module="CarouselContainer"]').forEach(el => {
        const module = new CarouselContainer([], el);
        module.init();
    });
};

export default CarouselContainer;
