
class Main {

	constructor(modules) {

		let _modules = modules.reduce((obj, x) => {
			obj[x.name()] = x;
			return obj;
		}, Object.create(null));

		this.instantiateModules = ({ selector='body', refresh=false }) => {
			let root = document.querySelector(selector);

			root.querySelectorAll('[data-module]').forEach((el) => {
				let _module = el.dataset.module;

				// only run refreshable modules when refresh is true
				if (_modules[_module] && (!refresh || _modules[_module].refreshable)) {
					let _props = {};

					if (el.dataset.props) {
						try {
							_props = JSON.parse(el.dataset.props);
						} catch (err) {
							console.error(err);
						}
					}

					if (_props.init !== false) {
						let instance = new _modules[_module](_props, el);
						instance.init();
					}
				}
			});
		};
	}

	init() {
		this.instantiateModules({});
	}

	refresh() {
		this.instantiateModules({ refresh: true });
	}
}

export default Main;
