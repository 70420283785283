class AccordianContainer {
    static name() { return 'AccordianContainer'; }
    static refreshable() { return true; }

    constructor(props, el) {
		this.props = props;
		this.el = el;
        this.items = [];
    }

    init() {
        console.info('~~~ Accordian Container ~~~');

        const accordianItems = this.el.getElementsByClassName('accordian-item');
        // skip processing if empty
        if (!accordianItems.length) return;

        const me = this;
        this.items = Array.from(accordianItems).map(item => new AccordianItem(item, me));
        // open first item
        this.items[0].open();
    }

    closeAccordianItems() {
        this.items.forEach(item => item.close());
    }
}

class AccordianItem {
    constructor(item, container) {
        this.item = item;
        this.container = container;
        this.content = item.querySelector('.accordian-content');
        this.isOpen = false;
        this.toggleButton = item.querySelector('button');

        const me = this; 
        this.toggleButton.addEventListener('click', () => {
            if (me.isOpen) return;

            me.container.closeAccordianItems();
            me.open();
        });
    }

    open() {
        // hide closed icon
        this.toggleButton.classList.add('d-none');
        // show content
        this.content.classList.remove('d-none');
    }

    close() {
        // show closed icon
        this.toggleButton.classList.remove('d-none');
        // hide content
        this.content.classList.add('d-none');
    }
}

export default AccordianContainer;
