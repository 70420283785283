import Main from './Main';
import AccordianContainer from './components/AccordianContainer';
import Carousel from './components/Carousel';
import CarouselContainer from './components/CarouselContainer';
import CookieBanner from './components/CookieBanner';
import Header from './components/Header';
import Search from './components/Search';
import SignUpForm from './components/SignUpForm';

/** Styles **/
import './styles/app.scss';

console.info('Sofia Health');

let modules = [
	AccordianContainer,
	Carousel,
	CarouselContainer,
	CookieBanner,
	Header,
	Search,
	SignUpForm,
];

let core = new Main(modules);
core.init();

window['refreshModules'] = function () { core.refresh(); }; 
